<template>
  <div class="col-12 mt-3">
            <div class="form-item">
              <div class="form-item__field"> 
                <input type="text" :placeholder="$t('platform.add_tb_link')" 
                :value="value"
                @input="handleInput" :class="{ invalid: invalid }" />
                <small v-show="invalid" class="error">
                  {{ $t("platform.url_invalid") }}
                </small>
              </div>
              <div class="d-flex mt-4">
                <small>{{ $t("platform.add_macros") }}</small>
                <div class="macros-list">
                  <div class="macros" :class="{ disable: value && value.includes(macros.name)}" 
                  @click="$emit('addMacros',field,index, macros)" v-for="(macros, index) in macroses" :key="index">{{ macros.value }}</div>
                </div>
              </div>
            </div>
          </div>
</template>

<script>
export default {
  props:{
    invalid:{
      type:Boolean,
      default:false,
    },
    value: String,
    macroses:{
      type: Array
    }
  },
  data(){
    return {
      field:""
    }
  },
  methods:{
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

 
<style lang="scss" scoped>
.macros-list {
  display: flex;
  margin-left: auto;
  column-gap: 8px;
  @media screen and (max-width: 550px) {
    overflow: auto;
  }
  .macros {
    height: fit-content;
    cursor: pointer;
    padding: 2px 6px;
    border: 1px solid grey;
    color: #1a1919;
    border-radius: 4px;
    font-size: 14px;
    &:hover {
      border: 1px solid #2952d9;
      color: #2952d9;
    }
    &.disable {
       cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
</style>