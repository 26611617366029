<template>
  <div class="col-12 col-xl-7">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-md-auto">
        <h1>
          {{
            isEdit ? $t("platform.edit_platform") : $t("platform.create_new")
          }}
        </h1>
      </div>
    </div>
    <div class="sep _h10"></div>
    <div class="row">
      <div class="col-12">
        <div class="form-item pb-0">
          <div class="form-item__label">
            {{ $t("platform.create.name") }}
          </div>
          <div class="form-item__field">
            <input
              ref="name"
              v-model="platform.name"
              type="text"
              :class="{ invalid: $v.platform.name.$error }"
            />
            <small
              v-if="
                $v.platform.name.$error &&
                (!$v.platform.name.minLength || platform.name === '')
              "
              class="error"
            >
              {{ $t("min_len_pass", { number: "3" }) }}
            </small>
            <small
              v-if="$v.platform.name.$error && !$v.platform.name.maxLength"
              class="error"
            >
              {{ $t("max_len_pass", { number: 52 }) }}
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-item">
          <div class="form-item__label">
            {{ $t("platform.payment_model") }}
          </div>
          <div class="form-item__field">
            <multiselect
              class="mb-3"
              label="title"
              v-model="platform.payment_model"
              :options="paymentOptions"
              :searchable="false"
              placeholder=""
              :show-labels="false"
              :allowEmpty="false"
              :disabled="isEdit"
              :class="{
                disabled_cpl: !user.is_cpl_payment_model_allowed,
                invalid: $v.platform.payment_model.$error,
              }"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <span>{{ option.title }}</span>
              </template>
              <template slot="option" slot-scope="{ option }">
                <span>{{ option.title }}</span>
                <small class="hint" v-if="option.title === 'Revshare'">
                  {{ $t("platform.revshare_option") }}
                </small>
                <small class="hint" v-if="option.title === 'CPL'">
                  {{
                    user.is_cpl_payment_model_allowed
                      ? $t("platform.cpl_option")
                      : $t("platform.cpl_option_ask")
                  }}
                </small>
              </template>
            </multiselect>
            <small class="error" v-if="$v.platform.payment_model.$error">
              {{ $t("platform.choose_paymet_model") }}
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-item">
          <div class="form-item__label">
            {{ $t("platform.create.url") }}
          </div>
          <div class="form-item__field">
            <input
              ref="url"
              :disabled="isEdit"
              v-model="platform.url"
              :class="{
                invalid: $v.platform.url.$invalid && platform.url !== '',
              }"
              type="text"
              placeholder="https://"
            />
            <small
              v-if="
                $v.platform.url.$invalid &&
                !$v.platform.url.minLength &&
                platform.url !== ''
              "
              class="error"
            >
              {{ $t("min_len_pass", { number: "3" }) }}
            </small>
            <small
              v-else-if="$v.platform.url.$invalid && !$v.platform.url.maxLength"
              class="error"
            >
              {{ $t("max_len_pass", { number: 126 }) }}
            </small>
            <small
              v-else-if="
                $v.platform.url.$invalid &&
                !$v.platform.url.url &&
                platform.url !== ''
              "
              class="error"
            >
              {{ $t("platform.url_invalid") }}
            </small>
          </div>
        </div>
      </div>
    </div>
    <CheckSite
      class="row"
      :status="checkSiteStatus"
      :type="checkType"
      :url="platform.url"
      @setType="setCheckType"
      @setStatus="setCheckSiteStatus"
      v-if="!isEdit"
    />
    <div class="row" v-if="platform.payment_model.title !== 'CPL'">
      <div class="col-12">
        <div class="form-item">
          <div class="form-item__label">
            {{ $t("platform.disable_show_ads") }}
          
          </div>
          <div class="form-item__field">
            <multiselect
              :class="{ invalid: allSelectedCategories }"
              :label="`name_${$i18n.locale === 'ru' ? 'ru' : 'en'}`"
              :track-by="`name_${$i18n.locale === 'ru' ? 'ru' : 'en'}`"
              v-model="platform.disabledCategory"
              :options="disabledCategories"
              :searchable="false"
              placeholder=""
              :show-labels="false"
              :multiple="checkForMultiple"
              :allowEmpty="true"
            >
            </multiselect>
          </div>
          <small v-if="allSelectedCategories" class="hint error-text">
            <svg v-svg symbol="info" size="0 0 17 17" role="info" />
            {{ $t("platform.enable_category_error") }}</small
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-item">
          <div class="form-item__label">
            {{ $t("category") }}
          </div>
          <div class="form-item__field">
            <multiselect
              :disabled="isEdit"
              label="title"
              v-model="platform.category_id"
              :options="categories"
              :searchable="false"
              placeholder=""
              :show-labels="false"
              :allowEmpty="false"
            >
            </multiselect>
          </div>
        </div>
      </div>
       <div class="col-12">
        <label class="checkbox-switch">
          <input type="checkbox" v-model="platform.adult" checked />
          <span class="checkbox-switch__visible"></span>
          <span class="checkbox-switch__text">{{
            $t("platform.site.show_for_adult")
          }}</span>
        </label>
      </div>
      <div
        v-if="platform.payment_model.value === 0"
        class="col-12 col-md-auto pt-3"
      >
        <label class="checkbox-switch">
          <input type="checkbox" v-model="platform.feedsSales" checked />
          <span class="checkbox-switch__visible"></span>
          <span class="checkbox-switch__text info-popup">
            {{ $t("platform.sales_on_feeds") }}
          </span>
        </label>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="form-item">
          <div class="form-item__label info-popup">
            {{ $t("platform.postback") }}
            <span v-tooltip="$t('platform.postback_pop_up')">
              <svg v-svg symbol="info" size="0 0 17 17" role="info" />
            </span>
          </div>
          <div class="form-item__field _overflow">
            <input
              ref="postback_url"
              :class="{ invalid: $v.platform.postback_url.$invalid }"
              type="text"
              v-model="platform.postback_url"
            />
            <small v-if="$v.platform.postback_url.$invalid" class="error">
              {{ $t("platform.postback_url_val") }}
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-1">
     
      <div class="col-12">
        <div class="form-item mt-4 pb-0">
          <div>
            <button
              @click="createSite"
              :disabled="
                (checkSiteStatus !== 2 && !isEdit) || allSelectedCategories
              "
              class="btn _mob100"
            >
              {{ $t("platform.save_platform") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <notifications group="error" />
  </div>
</template>
<script>
import "@/assets/css/_page-platform.scss";
import "@/assets/css/_section.scss";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, url } from "vuelidate/lib/validators";
import CheckSite from "./CheckSite.vue";
import { mapGetters } from "vuex";
import ServicePanel from "@/api/servicePanel";

export default {
  name: "SitePlatform",
  mixins: [validationMixin],
  components: { CheckSite },
  props: {
    createPlatform: {
      type: Function,
      default: function () {},
    },
    editPlatform: {
      type: Function,
      default: function () {},
    },
    editedPlatform: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataLoaded: false,
      platform: {
        name: "",
        url: "",
        payment_model: {
          title: "Revshare",
          value: 0,
        },
        postback_url: "",
        disabledCategory: [],
        category_id: { title: "Adult", value: 1 },
        adult: true,
        feedsSales: false,
      },
      disabledCategories: [],
      paymentOptions: [
        {
          title: "CPL",
          value: 1,
        },
        {
          title: "Revshare",
          value: 0,
        },
      ],
      checkSiteStatus: 0,
      checkType: 1,
      categories: [
        { title: "Adult", value: 1 },
        { title: "Mainstream", value: 2 },
      ],
    };
  },
  validations: {
    platform: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(52),
      },
      url: {
        url,
        required,
        minLength: minLength(3),
        maxLength: maxLength(126),
      },
      postback_url: {
        url,
        maxLength: maxLength(4096),
      },
      payment_model: {
        required,
      },
    },
  },
  mounted() {
    this.getCategories().then(() => {
      if (this.editedPlatform) {
        this.platform = {
          name: this.editedPlatform.name,
          payment_model:
            this.editedPlatform.payment_model == 1
              ? { title: "CPL", value: 1 }
              : { title: "Revshare", value: 0 },
          url: this.editedPlatform.url,
          disabledCategory:
            this.disabledCategories.filter((item) =>
              this.editedPlatform.disabled_category_ids.includes(item.id)
            ) || [],
          postback_url: this.editedPlatform.postback_url,
          category_id: this.categories.find(
            (el) => el.value === this.editedPlatform.category_id
          ),
          adult: Boolean(this.editedPlatform.adult),
          feedsSales: this.editedPlatform.deny_sell_to_feeds,
        };
        this.checkType = this.editedPlatform?.check_type || 1;
      }
    });
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    allSelectedCategories() {
      if (this.platform.disabledCategory)
        return this.platform.disabledCategory[0]?.id === 0;
      else return false;
    },
    checkForMultiple() {
      if (
        this.dataLoaded &&
        this.platform.disabledCategory &&
        this.disabledCategories &&
        this.platform.disabledCategory.length === this.disabledCategories.length
      ) {
        // eslint-disable-next-line
        this.platform.disabledCategory = [
          { name_en: "All", name_ru: "Все", id: 0 },
        ];
        return false;
      }
      return true;
    },
  },
  methods: {
    async getCategories() {
      await ServicePanel.getCategories().then((res) => {
        if (res && res.data) {
          this.disabledCategories.push(...res.data);
          this.dataLoaded = true;
        } else console.log(res);
      });
    },
    setCheckSiteStatus(val) {
      this.checkSiteStatus = val;
    },
    setCheckType(val) {
      this.checkType = val;
    },
    createSite() {
      if (this.checkSiteStatus !== 2 && !this.isEdit) return;
      this.$v.$touch();
      if (this.$v.$invalid || this.allSelectedCategories) {
        return;
      }

      if (this.isEdit) {
        let payload = {
          name: this.platform.name,
          disabled_category_ids:
            this.platform.disabledCategory.map((el) => el.id) || [],
          payment_model: +this.platform.payment_model.value,
          adult: +this.platform.adult,
          postback_url: this.platform.postback_url,
          deny_sell_to_feeds:
            this.platform.payment_model.value === 0
              ? +this.platform.feedsSales
              : 0,
        };
        if (!this.$v.$invalid) {
          this.$emit("editPlatform", payload);
          this.$v.$reset();
        }
      } else {
        const payload = {
          check_type: this.checkType,
          name: this.platform.name,
          disabled_category_ids:
            this.platform.disabledCategory.map((el) => el.id) || [],
          payment_model: this.platform.payment_model.value,
          url: this.platform.url,
          category_id: this.platform.category_id.value,
          postback_url: this.platform.postback_url,
          adult: +this.platform.adult,
          deny_sell_to_feeds:
            this.platform.payment_model.value === 0
              ? +this.platform.feedsSales
              : 0,
        };
        if (!this.$v.$invalid) {
          this.$emit("createPlatform", payload);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect.invalid {
  border: 1px solid #d02121;
}
.error-text {
  display: flex;
  column-gap: 4px;
  color: #d02121;
  svg {
    fill: #d02121;
  }
}
.disabled_cpl {
  &::v-deep {
    .multiselect__content-wrapper li:first-child span {
      pointer-events: none;
      cursor: not-allowed;
      background: #ededed !important;
      color: #a6a6a6 !important;
    }
  }
}

.button-wrapper {
  gap: 16px 0;
}
</style>