<template>
  <form class="row">
    <div class="col-12 col-xl-6">
      <div class="title-row row justify-content-between">
        <div class="col-12 col-md-auto">
          <h1>
            {{
              isEdit ? $t("platform.edit_platform") : $t("platform.create_new")
            }}
          </h1>
        </div>
      </div>
      <div class="sep _h10"></div>
      <div class="row">
        <div class="col-12">
          <div class="form-item">
            <div class="form-item__label">
              {{ $t("platform.arbitrage.name") }}
            </div>
            <div class="form-item__field">
              <input
                ref="name"
                v-model="platform.name"
                type="text"
                :class="{ invalid: $v.platform.name.$error }"
              />
              <small
                v-if="
                  $v.platform.name.$error &&
                  (!$v.platform.name.minLength || platform.name === '')
                "
                class="error"
                >{{ $t("min_len_pass", { number: "3" }) }}</small
              >
              <small
                v-if="$v.platform.name.$error && !$v.platform.name.maxLength"
                class="error"
                >{{
                  $t("max_len_pass", {
                    number: 52,
                  })
                }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-item">
            <div class="form-item__label">
              {{ $t("platform.payment_model") }}
            </div>
            <div class="form-item__field">
              <multiselect
                label="title"
                v-model="platform.payment_model"
                :options="paymentOptions"
                :searchable="false"
                placeholder=""
                :show-labels="false"
                :allowEmpty="false"
                :disabled="isEdit"
                :class="{
                  disabled_cpl: !user.is_cpl_payment_model_allowed,
                  invalid: $v.platform.payment_model.$error,
                }"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <span>{{ option.title }}</span>
                </template>
                <template slot="option" slot-scope="{ option }">
                  <span>{{ option.title }}</span>
                  <small class="hint" v-if="option.title === 'Revshare'">
                    {{ $t("platform.revshare_option") }}
                  </small>
                  <small class="hint" v-if="option.title === 'CPL'">
                    {{
                      user.is_cpl_payment_model_allowed
                        ? $t("platform.cpl_option")
                        : $t("platform.cpl_option_ask")
                    }}
                  </small>
                </template>
              </multiselect>
              <small class="error" v-if="$v.platform.payment_model.$error">
                {{ $t("platform.choose_paymet_model") }}
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="form-item__label mt-4">
        {{ $t("platform.arbitrage.accept") }}
      </div>
      <div class="row ml-1 mt-4 traffback">
        <div class="radio" @click="platform.tb_subscription = 'default'">
          <input
            type="radio"
            v-model="platform.tb_subscription"
            name="default"
            checked
          />
          <div
            class="radio__visible"
            :class="{ active: platform.tb_subscription === 'default' }"
          ></div>
          <div class="form-item__label mb-0 info-popup">
            {{ $t("platform.keep_complexity") }}
            <span v-tooltip="$t('platform.keep_complexity_tooltip')">
              <svg v-svg symbol="info" size="0 0 21 21" role="info" />
            </span>
          </div>
        </div>

        <div class="radio" @click="platform.tb_subscription = 'partners'">
          <input
            type="radio"
            v-model="platform.tb_subscription"
            name="partners"
            checked
          />
          <div
            class="radio__visible"
            :class="{ active: platform.tb_subscription === 'partners' }"
          ></div>
          {{ $t("platform.monetization_ph") }}
        </div>

        <div class="radio" @click="platform.tb_subscription = 'user'">
          <input type="radio" v-model="platform.tb_subscription" name="user" />
          <div
            class="radio__visible"
            :class="{ active: platform.tb_subscription === 'user' }"
          ></div>
          {{ $t("platform.send_tb_link") }}
        </div>

        <TraffbackWithMacros
          v-if="platform.tb_subscription === 'user'"
          v-model="platform.tb_subscription_url"
          @addMacros="addMacros"
          :macroses="macroses"
          :invalid="
            $v.platform.tb_subscription_url.$error ||
            !$v.platform.tb_subscription_url.url
          "
        />
      </div>

      <div class="form-item__label mt-5">
        {{ $t("platform.arbitrage.reject") }}
      </div>

      <div class="row ml-1 mt-4 traffback">
        <div class="radio" @click="platform.tb_complexity = 'user'">
          <input
            type="radio"
            v-model="platform.tb_complexity"
            name="user2"
            checked
          />
          <div
            class="radio__visible"
            :class="{ active: platform.tb_complexity === 'user' }"
          ></div>
          {{ $t("platform.send_tb_link") }}
        </div>

        <div class="radio" @click="platform.tb_complexity = 'partners'">
          <input
            type="radio"
            v-model="platform.tb_complexity"
            name="partners"
          />
          <div
            class="radio__visible"
            :class="{ active: platform.tb_complexity === 'partners' }"
          ></div>
          {{ $t("platform.monetization_ph") }}
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div class="form-item">
            <TraffbackWithMacros
              v-if="platform.tb_complexity === 'user'"
              v-model="platform.tb_complexity_url"
              @addMacros="addRejectMacros"
              :macroses="macroses"
              :invalid="
                $v.platform.tb_complexity_url.$error ||
                !$v.platform.tb_complexity_url.url
              "
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-item">
            <div class="form-item__label info-popup">
              {{ $t("platform.postback") }}
              <span v-tooltip="$t('platform.postback_pop_up')">
                <svg v-svg symbol="info" size="0 0 17 17" role="info" />
              </span>
            </div>
            <div class="form-item__field _overflow">
              <input
                type="text"
                v-model="platform.postback_url"
                :class="{ invalid: $v.platform.postback_url.$invalid }"
              />
              <small v-if="$v.platform.postback_url.$invalid" class="error">
                {{ $t("platform.postback_url_val") }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4">
          <div class="form-item">
            <div class="form-item__label info-popup">
              {{ $t("platform.arbitrage.difficult") }}
              <span v-tooltip="$t('platform.difficult_pop_up')">
                <svg v-svg symbol="info" size="0 0 17 17" role="info" />
              </span>
            </div>
            <div class="form-item__field">
              <multiselect
                class="complex-select"
                v-model="platform.complexity"
                :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                :searchable="false"
                :show-labels="false"
                :allowEmpty="false"
              >
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 align-self-end pb-2">
          <div class="form-item">
            <div class="form-item__field">
              <label class="checkbox">
                <input type="checkbox" v-model="platform.fullscreen" />
                <span class="checkbox__visible"></span>
                <span class="checkbox__text">{{
                  $t("platform.arbitrage.fullsize_mode")
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="sep mb-2"></div>
      <div class="row flex-row-reverse justify-content-end d-none d-xl-flex">
        <div
          v-if="platform.payment_model.value === 0"
          class="col-12 d-flex pb-2"
        >
          <div class="form-item pb-0">
            <div class="form-item__field">
              <label class="checkbox-switch">
                <input type="checkbox" v-model="platform.feedsSales" checked />
                <span class="checkbox-switch__visible"></span>
                <span class="checkbox-switch__text info-popup">
                  {{ $t("platform.sales_on_feeds") }}
                </span>
              </label>
            </div>
          </div>
          <!-- <div class="form-item pb-0">
            <div class="form-item__field">
              <label class="checkbox-switch">
                <input
                  type="checkbox"
                  v-model="platform.monetizationTb"
                  checked
                />
                <span class="checkbox-switch__visible"></span>
                <span class="checkbox-switch__text info-popup">
                  {{ $t("platform.arbitrage.monetization") }}
                  <span v-tooltip="$t('platform.extra_profit_pop_up')">
                    <svg v-svg symbol="info" size="0 0 17 17" role="info" />
                  </span>
                </span>
              </label>
            </div>
          </div> -->
          <!-- <div class="form-item ml-3 pb-0">
            <div class="form-item__field">
              <label class="checkbox-switch">
                <input
                  type="checkbox"
                  v-model="platform.monetizationInpage"
                  checked
                />
                <span class="checkbox-switch__visible"></span>
                <span class="checkbox-switch__text info-popup">
                  {{ $t("platform.monetization_inpage") }}
                  <span v-tooltip="$t('platform.inpage_pop_up')">
                    <svg v-svg symbol="info" size="0 0 17 17" role="info" />
                  </span>
                </span>
              </label>
            </div>
          </div> -->
        </div>
        <div class="col-12 col-md-auto">
          <div class="form-item pb-0">
            <div class="form-item__field">
              <button
                type="submit"
                @click.prevent="createArbitrage"
                class="btn _mob100"
              >
                {{ $t("platform.save_platform") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-6 d-flex flex-wrap">
      <div class="landings">
        <div class="section" :class="{ invalid: $v.platform.lands.$error }">
          <div class="landings__head">
            <h3>{{ $t("platform.arbitrage.choose_landing") }}</h3>
            <p class="max-390">
              {{ $t("platform.arbitrage.if_more_than_1_landing") }}
            </p>
          </div>
          <Tabs
            class="land-tabs"
            :tabs="tabs"
            :currentTab="currentTab"
            @setTab="setCurrentTab"
          />
          <div class="landings__list mt-3">
            <div
              class="landings__item landing"
              :class="{
                list: order === 'list',
                grid: order === 'grid',
                active: platform.lands.find((id) => id === land.id),
              }"
              v-for="(land, ind) in filteredLandings"
              :key="ind"
              @click="addLandingId(land)"
            >
              <div class="landing__thumb thumb">
                <img :src="`${land.image}`" alt="" />
              </div>
              <div class="landing__body">
                <div class="landing__title">
                  {{ $i18n.locale === "ru" ? land.name_ru : land.name_en }}
                </div>
                <div class="landing__footer">
                  <button
                    @click.stop.prevent="openLandModal(land)"
                    class="btn icon-view"
                  >
                    {{ $t("platform.arbitrage.watch") }}
                  </button>
                  <label class="checkbox-landing">
                    <button
                      type="button"
                      @click.stop.prevent="addLandingId(land)"
                    >
                      <svg
                        v-svg
                        symbol="check-land"
                        size="0 0 21 21"
                        role="info"
                      />
                    </button>
                    <span class="checkbox-landing__border"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <p class="text-center mb-0 landings__total" v-if="landings">
            {{ $t("platform.arbitrage.active_landing") }}:
            <strong>{{ platform.lands.length }}</strong> {{ $t("from") }}
            {{ landings.length }}
          </p>
          <ul class="landings__view">
            <li>
              <button
                class="btn _icon _view"
                :class="{ active: order === 'grid' }"
                @click.prevent="order = 'grid'"
              >
                <svg v-svg symbol="grid" size="0 0 14 14" role="info" />
              </button>
            </li>
            <li>
              <button
                class="btn _icon _view"
                :class="{ active: order === 'list' }"
                @click.prevent="order = 'list'"
              >
                <svg v-svg symbol="list" size="0 0 12 12" role="info" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <modal
      @closed="clearLandingModal"
      :width="780"
      name="landModal"
      height="auto"
      :scrollable="true"
      classes="creative-modal"
      :shiftY="0"
    >
      <LandingModal :landing="currentLanding" @closeModal="closeLandingModal" />
    </modal>
    <div class="row justify-content-between mt-3 d-xl-none w-100">
      <div class="col-12 col-sm-6 col-md-auto">
        <div class="form-item pb-0">
          <div class="form-item__field">
            <button
              type="submit"
              @click.prevent="createArbitrage"
              class="btn _mob100"
            >
              {{ $t("platform.save_platform") }}
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="platform.payment_model.value === 0"
        class="col-12 col-sm-6 d-flex pb-2 checkbox-arbitrage-wrapper"
      >
        <div class="form-item pb-0 d-flex align-items-center">
          <div class="form-item__field mb-0">
            <label class="checkbox-switch">
              <input type="checkbox" v-model="platform.feedsSales" checked />
              <span class="checkbox-switch__visible"></span>
              <span class="checkbox-switch__text info-popup">
                {{ $t("platform.sales_on_feeds") }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import "@/assets/css/_page-platform.scss";
import "@/assets/css/_page-add-platform.scss";
import "@/assets/css/_section.scss";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
import LandingModal from "@/components/LandingModal";
import LandingMixin from "@/mixins/landModal";
import TraffbackWithMacros from "@/components/TraffbackWithMacros.vue";
import Tabs from "@/components/statictics/Tabs.vue";
import { mapGetters } from "vuex";

export default {
  name: "ArbitragePlatform",
  mixins: [validationMixin, LandingMixin],
  components: { LandingModal, TraffbackWithMacros, Tabs },
  props: {
    landings: {
      type: Array,
      default: null,
    },
    editedPlatform: {
      type: Object,
      default: null,
    },
    createPlatform: {
      type: Function,
      default: function () {},
    },
    editPlatform: {
      type: Function,
      default: function () {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      platform: {
        complexity: 3,
        lands: [],
        fullscreen: false,
        name: "",
        monetizationTb: false,
        monetizationInpage: false,
        payment_model: {
          title: "Revshare",
          value: 0,
        },
        tb_subscription: "default",
        tb_complexity: "partners",
        tb_subscription_url: "",
        tb_complexity_url: "",
        postback_url: "",
        feedsSales: false,
      },
      tabs: ["all", "adult", "mainstream"],
      currentTab: "all",
      macroses: [
        {
          name: "site",
          value: "{SITE}",
        },
        {
          name: "p1",
          value: "{P1}",
        },
        {
          name: "p2",
          value: "{P2}",
        },
        {
          name: "p3",
          value: "{P3}",
        },
        {
          name: "p4",
          value: "{P4}",
        },
        {
          name: "p4",
          value: "{P4}",
        },
        {
          name: "land",
          value: "{LAND}",
        },
        {
          name: "tid",
          value: "{TID}",
        },
      ],

      paymentOptions: [
        {
          title: "CPL",
          value: 1,
        },
        {
          title: "Revshare",
          value: 0,
        },
      ],
      order: "grid",
    };
  },
  validations: {
    platform: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(52),
      },
      tb_subscription_url: {
        required: requiredIf(function () {
          return this.platform.tb_subscription === "user";
        }),
        url,
        maxLength: maxLength(356),
      },
      tb_complexity_url: {
        required: requiredIf(function () {
          return this.platform.tb_complexity === "user";
        }),
        url,
        maxLength: maxLength(356),
      },
      postback_url: {
        url,
        maxLength: maxLength(4096),
      },
      lands: {
        minLength: (value) => {
          return value.length >= 1;
        },
      },
      payment_model: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    filteredLandings() {
      if (this.currentTab === "adult") {
        return this.landings.filter((el) => el.category == 1);
      } else if (this.currentTab === "mainstream") {
        return this.landings.filter((el) => el.category == 2);
      } else return this.landings.map((el) => el);
    },
    checkError() {
      const generalError =
        this.$v.platform.lands.$invalid ||
        this.$v.platform.name.$invalid ||
        this.$v.platform.payment_model.$invalid ||
        this.$v.platform.postback_url.$invalid;
      if (
        this.platform.tb_complexity !== "user" &&
        this.platform.tb_subscription !== "user"
      ) {
        return generalError;
      } else if (
        this.platform.tb_complexity !== "user" &&
        this.platform.tb_subscription === "user"
      ) {
        return generalError || this.$v.platform.tb_subscription_url.$invalid;
      } else if (
        this.platform.tb_complexity === "user" &&
        this.platform.tb_subscription !== "user"
      ) {
        return generalError || this.$v.platform.tb_complexity_url.$invalid;
      } else {
        return this.$v.$invalid;
      }
    },
  },
  mounted() {
    if (this.isEdit) {
      this.platform = {
        complexity: this.editedPlatform.complexity,
        payment_model:
          this.editedPlatform.payment_model == 1
            ? { title: "CPL", value: 1 }
            : { title: "Revshare", value: 0 },
        tb_subscription: this.editedPlatform.tb_subscription,
        tb_complexity: this.editedPlatform.tb_complexity,
        lands: this.editedPlatform.lands || [],
        fullscreen: this.editedPlatform.fullscreen,
        name: this.editedPlatform.name,
        tb_subscription_url: this.editedPlatform.tb_subscription_url,
        tb_complexity_url: this.editedPlatform.tb_complexity_url,
        postback_url: this.editedPlatform.postback_url,
        feedsSales: this.editedPlatform.deny_sell_to_feeds,
      };
    }
  },
  methods: {
    setCurrentTab(tab) {
      this.currentTab = tab;
    },
    addMacros(field, _, macros) {
      field = this.platform.tb_subscription_url;

      let queryCheck1 = `&${macros.name}=${macros.value}`;
      let queryCheck2 = `?${macros.name}=${macros.value}`;

      if (field.includes(`&${macros.name}`)) {
        field = field.replace(queryCheck1, "");
      } else if (field.includes(`?${macros.name}`)) {
        field = field.replace(queryCheck2, "");
        if (!field.includes("?")) {
          field = field.replace("&", `?`);
        }
      } else if (field.includes("?")) {
        field += queryCheck1;
        this.platform.tb_subscription_url = field;
      } else {
        field += queryCheck2;
        this.platform.tb_subscription_url = field;
      }
    },
    addRejectMacros(field, _, macros) {
      field = this.platform.tb_complexity_url;

      let queryCheck1 = `&${macros.name}=${macros.value}`;
      let queryCheck2 = `?${macros.name}=${macros.value}`;

      if (field.includes(`&${macros.name}`)) {
        field = field.replace(queryCheck1, "");
      } else if (field.includes(`?${macros.name}`)) {
        field = field.replace(queryCheck2, "");
        if (!field.includes("?")) {
          field = field.replace("&", `?`);
        }
      } else if (field.includes("?")) {
        field += queryCheck1;
        this.platform.tb_complexity_url = field;
      } else {
        field += queryCheck2;
        this.platform.tb_complexity_url = field;
      }
    },

    addLandingId(el) {
      if (this.platform.lands.find((ind) => ind === el.id)) {
        this.platform.lands = this.platform.lands.filter(
          (ind) => ind !== el.id
        );
      } else {
        this.platform.lands.push(el.id);
      }
    },
    createArbitrage() {
      this.$v.$touch();

      if (this.checkError) {
        return;
      }

      if (this.isEdit) {
        let payload = {
          name: this.platform.name,
          complexity: +this.platform.complexity,
          tb_subscription: this.platform.tb_subscription,
          tb_complexity: this.platform.tb_complexity,
          tb_subscription_url:
            this.platform.tb_subscription === "user"
              ? this.platform.tb_subscription_url
              : undefined,
          tb_complexity_url:
            this.platform.tb_complexity === "user"
              ? this.platform.tb_complexity_url
              : undefined,
          fullscreen: +this.platform.fullscreen,
          lands: this.platform.lands,
          postback_url: this.platform.postback_url,
          payment_model: +this.platform.payment_model.value,
          monetization_trafficback: +this.monetizationTb,
          monetization_inpage: +this.monetizationInpage,
          deny_sell_to_feeds:
            this.platform.payment_model.value === 0
              ? +this.platform.feedsSales
              : 0,
        };
        if (!this.checkError) {
          this.$emit("editPlatform", payload);
          this.$v.$reset();
        }
      } else {
        const payload = {
          name: this.platform.name,
          payment_model: this.platform.payment_model.value,
          tb_subscription: this.platform.tb_subscription,
          tb_complexity: this.platform.tb_complexity,
          complexity: this.platform.complexity,
          tb_subscription_url:
            this.platform.tb_subscription === "user"
              ? this.platform.tb_subscription_url
              : undefined,
          tb_complexity_url:
            this.platform.tb_complexity === "user"
              ? this.platform.tb_complexity_url
              : undefined,
          lands: this.platform.lands,
          postback_url: this.platform.postback_url,
          fullscreen: +this.platform.fullscreen,
          deny_sell_to_feeds:
            this.platform.payment_model.value === 0
              ? +this.platform.feedsSales
              : 0,
        };
        if (!this.checkError) {
          this.$emit("createPlatform", payload);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.land-tabs ::v-deep li {
  width: 33%;
}

.invalid {
  border: 1px solid red;
}

.complex-select ::v-deep .multiselect__content-wrapper {
  max-height: 175px !important;
}

.traffback {
  display: flex;
  column-gap: 16px;
  row-gap: 16px;
}

@media screen and (max-width: 768px) {
  .traffback {
    flex-flow: column wrap;
  }
}

.disabled_cpl {
  &::v-deep {
    .multiselect__content-wrapper li:first-child span {
      pointer-events: none;
      cursor: not-allowed;
      background: #ededed !important;
      color: #a6a6a6 !important;
    }
  }
}

.checkbox-switch__text {
  width: max-content;
}

.checkbox-arbitrage-wrapper {
  @media (min-width: 576px) {
    justify-content: end;
  }
}
</style>
