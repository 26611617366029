<template>
  <div>
    <div class="col-12">
      <h3 class="mb-4">
        {{ $t("platform.site.confirm_site.confirm_your_site") }}
      </h3>
      <ul class="tabs _mob100">
        <li class="flex-grow-1">
          <button
            class="w-100"
            :class="{ active: type === 1 }"
            @click="setType(1)"
          >
            {{ $t("platform.site.confirm_site.txt_file") }}
          </button>
        </li>
        <li class="flex-grow-1">
          <button
            @click="setType(2)"
            class="w-100"
            :class="{ active: type === 2 }"
          >
            {{ $t("platform.site.confirm_site.meta_tag") }}
          </button>
        </li>
      </ul>
      <div class="tubs-content pt-2">
        <div v-if="type === 1" class="tubs-content__item active">
          <div class="form-item">
            <div class="form-item__label mb-4">
              {{ $t("platform.site.confirm_site.download_file_1") }}
              <span class="font-weight-bold">partners-house.txt</span>
              {{ $t("platform.site.confirm_site.download_file_2") }}
            </div>
            <div class="form-item__field">
              <button @click="downloadFile" class="btn _border _mob100">
                <span class="icon-download mr-2">
                  <svg
                    v-svg
                    symbol="download"
                    size="0 0 16 16"
                    role="info" /></span
                >partners-house.txt
              </button>
            </div>
            <p class="pt-3 mb-1">
              {{ $t("platform.site.confirm_site.check_file") }}
            </p>
          </div>
        </div>
        <div v-else-if="type === 2" class="tubs-content__item">
          <div class="form-item">
            <div class="form-item__label mb-4">
              {{ $t("platform.site.confirm_site.put_meta_1") }}
              <span class="font-weight-bold"
                >meta {{ $t("platform.site.confirm_site.tag") }}</span
              >
              {{ $t("platform.site.confirm_site.put_meta_2") }}
            </div>
            <div class="form-item__field">
              <div class="form-item__field _copy">
                <input
                  id="meta"
                  type="text"
                  readonly
                  class="_green"
                  v-model="useTag"
                />
                <button @click="copy" type="button" class="btn icon-copy">
                  <svg v-svg symbol="copy" size="0 0 13 15" role="info" />
                </button>
              </div>
            </div>
            <p class="pt-3 mb-1">
              {{ $t("platform.site.confirm_site.check_meta") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 pb-4">
      <div class="row align-items-center">
        <div class="col-12 col-md-auto mb-4">
          <button
            @click="checkStatus"
            :disabled="!url.length || status === 4"
            class="btn _border _green _mob100"
          >
            {{ $t("platform.site.confirm_site.check") }}
          </button>
        </div>
        <div class="col-12 col-md-auto mb-4">
          <div v-if="status === 1" class="status">
            <div class="status__label">
              {{ $t("platform.site.confirm_site.status") }}:
            </div>
            <div class="status__result">
              <div class="status__load">
                {{ $t("platform.site.confirm_site.checking") }}
              </div>
            </div>
          </div>
          <div v-else-if="status === 2" class="status">
            <div class="status__label">
              {{ $t("platform.site.confirm_site.status") }}:
            </div>
            <div class="status__result">
              <div class="status__confirmed">
                <span class="icon-confirm">
                  <svg v-svg symbol="confirm" size="0 0 22 22" role="info" />
                </span>
                {{ $t("platform.site.confirm_site.confirm") }}
              </div>
            </div>
          </div>
          <div v-else-if="status === 3 || status === 4" class="status">
            <div class="status__label">
              <span> {{ $t("platform.site.confirm_site.status") }}: </span>
            </div>
            <div class="status__result">
              <div class="status__error" :class="{ center: status === 4 }">
                <span class="icon-no-confirm">
                  <svg v-svg symbol="no-confirm" size="0 0 22 22" role="info" />
                </span>
                <span>
                  {{
                    $t(
                      `platform.site.confirm_site.${
                        status === 3 ? "no_confirm" : "max_try"
                      }`
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notifications group="foo" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import subaccsService from "@/api/subaccsService";

export default {
  name: "CheckSite",
  props: {
    url: {
      type: String,
      default: "",
    },
    status: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    useTag() {
      return `<meta confirm="partners-house-${this.user.id}"` + `/` + `>`;
    },
  },
  methods: {
    setType(val) {
      this.$emit("setStatus", 0);
      this.$emit("setType", val);
    },
    downloadFile() {
      subaccsService.getFile().then((res) => {
        if (res) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "partners-house.txt");
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    checkStatus() {
      if (this.url.length) {
        const payload = {
          check_type: this.type,
          url: this.url,
        };
        this.$emit("setStatus", 1);
        subaccsService.checkSite(payload).then((res) => {
          if (res.status === 400) {
            if (res.message.includes("10")) {
              this.$emit("setStatus", 4);
            } else {
              this.$emit("setStatus", 3);
            }
          } else if (res.status === 200) {
            this.$emit("setStatus", 2);
          }
        });
      }
    },
    copy() {
      this.$helper.copyCode("meta");
      this.$notify({
        group: "foo",
        title: this.$t("ref_system.copied"),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
._border {
  &:hover {
    svg {
      fill: #fff;
    }
  }
}
</style>